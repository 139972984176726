import dayjs from '@/lib/dayjs'
import { bubbleChartOptions, donutChartOptions } from '@/config/dashboard'
import {
  getChartData,
  truncateDecimal,
  getDateOfRange,
} from '@/utils/dashboard'
import { getCssVar } from '@/utils/helper'

const dayFormat = (date, dateFormat) => {
  return dayjs(date).format(dateFormat)
}

export const yaxisTitleConfig = {
  sumAmount: '金額',
  count: '單數',
  avgAmount: '金額',
  avgMemberCount: '單數',
}

//  首購回購氣泡圖default
export const generateFirstPurchaseConfig = (series = [], yaxisTitle) => ({
  ...bubbleChartOptions,
  xaxis: {
    tickAmount: 30,
    type: 'datetime',
    title: {
      text: '日期',
    },
    labels: {
      formatter: (value, timestamp, opts) => {
        return dayjs(timestamp).format('YYYY/MM/DD')
      },
    },
  },
  yaxis: [
    {
      title: {
        text: yaxisTitle,
      },
    },
  ],
  series,
})

export const processData = (labels, data, type, defaultObject) => {
  const result = {}
  data
    .filter((item) => item.isRepurchase === type)
    .forEach((item) => {
      result[item.createdAt] = item
    })
  return labels.map((item) =>
    result[item]
      ? {
        ...result[item],
        createdAt: dayjs(result[item].createdAt).valueOf(),
      }
      : {
        ...defaultObject,
        createdAt: dayjs(item).valueOf(),
      },
  )
}

export const getFirstPurchaseData = async ({ shopId, interval, splitType }) => {
  const dateFormat = 'YYYY/MM/DD'

  const dateRange = getDateOfRange({
    interval,
    splitType,
  })

  const { result, lastUpdatedAt } = await getChartData({
    shopId,
    key: 'transactionTrendByRepurchase',
    dateTrunc: splitType,
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })

  const data = result.map((item) => ({
    ...item,
    createdAt: dayFormat(item.createdAt, dateFormat),
  }))

  //  init data
  const defaultObject = {
    memberCount: 0,
    sumAmount: 0,
    count: 0,
    avgAmount: 0,
    avgMemberCount: 0,
  }

  const firstPurchase = processData(
    dateRange.dateArray,
    data,
    '首購',
    defaultObject,
  )
  const repurchase = processData(
    dateRange.dateArray,
    data,
    '回購',
    defaultObject,
  )

  return {
    firstPurchase,
    repurchase,
    lastUpdatedAt,
  }
}

//  年齡消費default
export const generateAgeConfig = ({ labels = [], series = [] }) => ({
  ...donutChartOptions,
  chart: {
    ...donutChartOptions.chart,
    height: '470',
  },
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
    getCssVar('chart-6'),
    getCssVar('chart-7'),
    getCssVar('chart-8'),
    getCssVar('chart-9'),
    getCssVar('chart-10'),
    getCssVar('chart-11'),
    getCssVar('chart-12'),
    // '#204bb4',
    // '#D72C4A',
    // '#FFB800',
    // '#00CB20',
    // '#BB01DA',
    // '#2FCDFF',
    // '#D26500',
    // '#A7C200',
    // '#FF29C3',
    // '#767676',
    // '#2BB8C1',
    // '#D5F4F6',
  ],
  plotOptions: {
    pie: {
      customScale: 0.5,
    },
  },
  labels,
  series,
})

const initAgeDataSet = (ageLabels) => {
  return ageLabels.map(() => 0)
}

export const getAgeData = async ({ shopId, interval, ageLabels }) => {
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  const { result } = await getChartData({
    shopId,
    key: 'transactionRateByAge',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })

  const ageDataset = initAgeDataSet(ageLabels)

  const data = {
    sumAmount: [...ageDataset],
    count: [...ageDataset],
    avgAmount: [...ageDataset],
    avgMemberCount: [...ageDataset],
  }

  result.forEach((item) => {
    const ageIndex = ageLabels.findIndex((key) => key === item.age_range)
    data.sumAmount[ageIndex] = truncateDecimal(item.sumAmount)
    data.count[ageIndex] = truncateDecimal(item.count)
    data.avgAmount[ageIndex] = truncateDecimal(item.avgAmount)
    data.avgMemberCount[ageIndex] = truncateDecimal(item.avgMemberCount)
  })

  return data
}

//  性別default
export const generateGenderConfig = ({ labels = [], series = [] }) => ({
  ...donutChartOptions,
  colors: [getCssVar('chart-1'), getCssVar('chart-2'), getCssVar('chart-3')],
  labels,
  series,
})

const initGenderDataset = (genderKeys) => {
  return genderKeys.map(() => 0)
}

export const getGenderData = async ({ shopId, interval, genderLabels }) => {
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  const { result } = await getChartData({
    shopId,
    key: 'transactionRateByGender',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })

  const genderKeys = Object.keys(genderLabels)

  const genderDataset = initGenderDataset(genderKeys)

  const data = {
    sumAmount: [...genderDataset],
    count: [...genderDataset],
    avgAmount: [...genderDataset],
    avgMemberCount: [...genderDataset],
  }

  result.forEach((item) => {
    const genderIndex = genderKeys.findIndex(
      (key) => key === (item.gender || 'other'),
    )
    data.sumAmount[genderIndex] = truncateDecimal(item.sumAmount)
    data.count[genderIndex] = truncateDecimal(item.count)
    data.avgAmount[genderIndex] = truncateDecimal(item.avgAmount)
    data.avgMemberCount[genderIndex] = truncateDecimal(item.avgMemberCount)
  })
  return data
}
