<template>
  <div class="member-contribution-overview">
    <PageTitle title="會員貢獻度" hideBtn />
    <div class="updateAt">
      <p>{{ updateAt }}</p>
      <p>{{ updateTime }}</p>
    </div>
    <section class="flex flex-col gap-10">
      <FirstPurchaseRateStatistics class="chart-block" @getTime="getTime" />
      <AgeConsumptionStatistics class="chart-block" />
      <GenderConsumptionStatistics class="chart-block" />
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import FirstPurchaseRateStatistics from './components/FirstPurchaseRateStatistics/FirstPurchaseRateStatistics.vue'
import GenderConsumptionStatistics from './components/GenderConsumptionStatistics/GenderConsumptionStatistics.vue'
import AgeConsumptionStatistics from './components/AgeConsumptionStatistics/AgeConsumptionStatistics.vue'

export default defineComponent({
  name: 'MemberContributionOverview',
  components: {
    FirstPurchaseRateStatistics,
    GenderConsumptionStatistics,
    AgeConsumptionStatistics,
  },
  setup () {
    const time = ref(null)

    const updateAt = computed(() => {
      return `數據截止日：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD') : '-'
      }`
    })
    const updateTime = computed(() => {
      return `數據更新時間：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD HH:mm:ss') : '-'
      }`
    })
    const getTime = (e) => {
      time.value = e
    }
    return {
      getTime,
      updateAt,
      updateTime,
    }
  },
})
</script>

<style lang="postcss" scoped>
.updateAt {
  @apply flex justify-end gap-[20px];
  @apply text-[#767676];
  @apply pb-[34px];
}
.chart-block {
  @apply bg-[#fff];
  @apply p-5;
  @apply rounded;
}
</style>
